import React, { type ReactNode, type PropsWithChildren } from 'react';
import { Box, type XCSS, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { COLOR_MAP, GADGET_ERROR_TYPE } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import {
	GadgetErrorFallback,
	GadgetErrorFallbackBase,
} from '../../common/gadget/gadget-error-fallback';
import { GadgetAnalyticsContainer } from './gadget-analytics-container';
import { GadgetBoundary } from './gadget-boundary';

type Props = {
	gadgetData: GadgetData;
	isDisplayed: boolean;
	header: ReactNode;
	isContentHidden?: boolean;
	customWrapperStyles?: XCSS | Array<XCSS | false | undefined>;
	footer?: ReactNode;
	customPlaceholder?: ReactNode;
};

export function GadgetLayoutWithAnalyticsOld({
	gadgetData,
	isDisplayed,
	header,
	isContentHidden,
	customWrapperStyles,
	children,
	footer,
	customPlaceholder,
}: PropsWithChildren<Props>) {
	const { id, color, title, errorMessage } = gadgetData;

	return (
		<ContextualAnalyticsData objectType="gadget" objectId={gadgetData.id}>
			<JSErrorBoundary id="gadget" packageName="jiraDashboardInternalCommon" fallback="flag">
				<Box
					xcss={[
						isDisplayed ? wrapperStyles : gadgetNotDisplayedStyles,
						...(Array.isArray(customWrapperStyles) ? customWrapperStyles : [customWrapperStyles]),
						color && colorsMap[color],
					]}
					id={id}
				>
					<div id={`gadget-title-${id}`}>{header}</div>
					<Box aria-labelledby={`gadget-title-${id}`} id={`gadget-content-${id}`} role="region">
						<GadgetBoundary
							data={gadgetData}
							isError={!title}
							errorFallback={
								errorMessage ? (
									<GadgetErrorFallbackBase id={id} message={errorMessage} />
								) : (
									<GadgetErrorFallback
										id={id}
										errorType={
											!title ? GADGET_ERROR_TYPE.NOT_SUPPORTED_ERROR : GADGET_ERROR_TYPE.VIEW_ERROR
										}
									/>
								)
							}
							customPlaceholder={customPlaceholder}
						>
							<Box xcss={[isContentHidden ? contentHiddenStyles : contentStylesOld]}>
								<GadgetAnalyticsContainer data={gadgetData}>{children}</GadgetAnalyticsContainer>
								{footer}
							</Box>
						</GadgetBoundary>
					</Box>
				</Box>
			</JSErrorBoundary>
		</ContextualAnalyticsData>
	);
}

export function GadgetLayoutWithAnalyticsNew({
	gadgetData,
	isDisplayed,
	header,
	isContentHidden,
	customWrapperStyles,
	children,
	footer,
	customPlaceholder,
}: PropsWithChildren<Props>) {
	const { id, color, title, errorMessage } = gadgetData;

	return (
		<ContextualAnalyticsData objectType="gadget" objectId={gadgetData.id}>
			<JSErrorBoundary id="gadget" packageName="jiraDashboardInternalCommon" fallback="flag">
				<Box
					xcss={[
						isDisplayed ? wrapperStyles : gadgetNotDisplayedStyles,
						...(Array.isArray(customWrapperStyles) ? customWrapperStyles : [customWrapperStyles]),
						color && colorsMap[color],
					]}
					id={id}
				>
					<div id={`gadget-title-${id}`}>{header}</div>
					<Box aria-labelledby={`gadget-title-${id}`} id={`gadget-content-${id}`} role="region">
						<GadgetBoundary
							data={gadgetData}
							isError={!title}
							errorFallback={
								errorMessage ? (
									<GadgetErrorFallbackBase id={id} message={errorMessage} />
								) : (
									<GadgetErrorFallback
										id={id}
										errorType={
											!title ? GADGET_ERROR_TYPE.NOT_SUPPORTED_ERROR : GADGET_ERROR_TYPE.VIEW_ERROR
										}
									/>
								)
							}
							customPlaceholder={customPlaceholder}
						>
							<Box xcss={[isContentHidden ? contentHiddenStyles : contentStylesNew]}>
								<GadgetAnalyticsContainer data={gadgetData}>{children}</GadgetAnalyticsContainer>
								{footer}
							</Box>
						</GadgetBoundary>
					</Box>
				</Box>
			</JSErrorBoundary>
		</ContextualAnalyticsData>
	);
}

const wrapperStyles = xcss({
	boxShadow: 'elevation.shadow.raised',
	borderTopWidth: '4px',
	borderTopStyle: 'solid',
	borderRadius: 'border.radius.100',
	marginBottom: 'space.200',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface.raised', colors.N0),
	paddingInline: 'space.200',
	paddingBottom: 'space.200',
	position: 'relative',
	borderColor: 'color.border.brand',
});
const gadgetNotDisplayedStyles = xcss({
	display: 'none',
});

const colorsMap = Object.fromEntries(
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-next-line The borderColor value isn't a valid token
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	Object.entries(COLOR_MAP).map(([key, value]) => [key, xcss({ borderColor: value })]),
);

const contentStylesNew = xcss({ overflowWrap: 'anywhere' });
const contentStylesOld = xcss({ overflow: 'hidden' });
const contentHiddenStyles = xcss({ display: 'none' });

export const GadgetLayoutWithAnalytics = componentWithFG(
	'endeavour_gadget_layout_style_overflow',
	GadgetLayoutWithAnalyticsNew,
	GadgetLayoutWithAnalyticsOld,
);
