import React, { type ReactNode } from 'react';
import { IconButton } from '@atlaskit/button/new';
import VidFullScreenOnIcon from '@atlaskit/icon/core/migration/fullscreen-enter--vid-full-screen-on';
import MediaServicesFullScreenIcon from '@atlaskit/icon/core/migration/fullscreen-exit--media-services-full-screen';
import MediaServicesActualSizeIcon from '@atlaskit/icon/core/migration/grow-diagonal--media-services-actual-size';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import MediaServicesFitToPageIcon from '@atlaskit/icon/core/migration/shrink-diagonal--media-services-fit-to-page';
import { Flex } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { useDashboardAnalytics } from '../../../controllers/dashboard-analytics/main.tsx';
import { useGadgetRefresh } from '../../../controllers/gadget/context.tsx';
import messages from './messages';

type Props = {
	gadgetId: string;
	title: string;
	onMinimizeOrRestore: (isMinimized: boolean) => void;
	isMinimized: boolean;
	isMaximizable: boolean;
	onMaximizeOrRestore: (isMaximized: boolean) => void;
	isMaximized: boolean;
	additionalToolbarItems?: ReactNode;
};

export function GadgetToolbar({
	gadgetId,
	title,
	onMinimizeOrRestore,
	isMinimized,
	isMaximizable,
	onMaximizeOrRestore,
	isMaximized,
	additionalToolbarItems,
}: Props) {
	const onRefresh = useGadgetRefresh();

	return (
		<Flex>
			<MenuButton
				onClick={() => onMinimizeOrRestore(!isMinimized)}
				action="minimize-restore-button"
				tooltipMessageKey={messages[isMinimized ? 'expand' : 'minimize']}
				ariaLabelMessageKey={messages[isMinimized ? 'expandAriaLabel' : 'minimizeAriaLabel']}
				gadgetName={title}
				gadgetId={gadgetId}
				icon={isMinimized ? MediaServicesActualSizeIcon : MediaServicesFitToPageIcon}
			/>
			{isMaximizable && (
				<MenuButton
					onClick={() => onMaximizeOrRestore(!isMaximized)}
					action="maximize-restore-button"
					tooltipMessageKey={messages[isMaximized ? 'singleColumn' : 'maximize']}
					ariaLabelMessageKey={messages[isMaximized ? 'singleColumn' : 'maximizeAriaLabel']}
					gadgetId={gadgetId}
					gadgetName={title}
					icon={isMaximized ? MediaServicesFullScreenIcon : VidFullScreenOnIcon}
				/>
			)}
			<MenuButton
				gadgetId={gadgetId}
				onClick={onRefresh}
				action="refresh-button"
				tooltipMessageKey={messages.refresh}
				ariaLabelMessageKey={messages.refreshAriaLabel}
				gadgetName={title}
				icon={RefreshIcon}
			/>
			{additionalToolbarItems}
		</Flex>
	);
}

type MenuButtonProps = {
	gadgetId: string;
	action: string;
	onClick: () => void;
	tooltipMessageKey: MessageDescriptor;
	ariaLabelMessageKey: MessageDescriptor;
	gadgetName: string;
	icon: Parameters<typeof IconButton>[0]['icon'];
};

export const MenuButton = ({
	gadgetId,
	onClick,
	action,
	tooltipMessageKey,
	ariaLabelMessageKey,
	gadgetName,
	icon,
}: MenuButtonProps) => {
	const { formatMessage } = useIntl();
	const fireAnalytics = useDashboardAnalytics();

	const onClickHandler = () => {
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			actionSubject: `gadget.${action}`,
			action: 'clicked',
			actionSubjectId: 'gadget',
		});
		onClick();
	};

	const tooltipContent = formatMessage(tooltipMessageKey);
	const ariaLabel = formatMessage(ariaLabelMessageKey, { gadgetName });

	return (
		<Tooltip content={tooltipContent} hideTooltipOnClick position="top">
			<IconButton
				appearance="subtle"
				spacing="default"
				label={ariaLabel}
				onClick={onClickHandler}
				icon={icon}
				testId={`dashboard-internal-common.ui.gadget.toolbar.${action}`}
				aria-controls={`gadget-content-${gadgetId}`}
			/>
		</Tooltip>
	);
};
