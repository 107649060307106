// eslint-disable-next-line jira/restricted/react-lazy
import React, { lazy, useLayoutEffect, useCallback, useMemo } from 'react';
import type {
	GadgetMetricsEventType,
	GadgetContentType,
} from '@atlassian/jira-dashboard-common/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { DASHBOARD_GADGET_MODULE, ENTRY_POINT_EDIT } from '@atlassian/jira-forge-ui-constants';
import type { DashboardGadgetExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { InitialRenderPayload } from '@atlassian/jira-forge-ui-types/src/common/types/renderer.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import type { DashboardGadgetEdit } from '@atlassian/jira-forge-ui/src/ui/components/dashboard-gadget/edit/index.tsx';
import type { DashboardGadgetView } from '@atlassian/jira-forge-ui/src/ui/components/dashboard-gadget/view/index.tsx';
import type { LazyComponentProps, LazyRenderer } from '../../types';

const GADGET_START_EVENT = 'gadget-start';
const GADGET_FINISH_EVENT = 'gadget-rendering-finished';

export type ViewProps = LazyComponentProps<typeof DashboardGadgetView> & {
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void;
};

export type EditProps = LazyComponentProps<typeof DashboardGadgetEdit> & {
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void;
};

// We are using React.lazy() instead of react-loosley-lazy here because DashboardGadget
// renders RendererNext from the forge-ui package which uses Suspense
// and you can't mix React.Suspense with RLL

const getUiKitModule = () =>
	import(/* webpackChunkName: "async-forge-ui-dashboard-gadget" */ './index');

export const LazyDashboardGadgetView: LazyRenderer<typeof DashboardGadgetView> = withMetrics(
	lazy(() => getUiKitModule().then((module) => ({ default: module.DashboardGadgetView }))),
);

export const LazyDashboardGadgetEdit: LazyRenderer<typeof DashboardGadgetEdit> = withMetrics(
	lazy(() => getUiKitModule().then((module) => ({ default: module.DashboardGadgetEdit }))),
);

const getIframeModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-iframe-dashboard-gadget" */ '@atlassian/jira-forge-ui/src/common/ui/iframe/components/dashboard-gadget'
	);

export const LazyDashboardGadgetViewIframe = withMetrics(
	lazy(() => getIframeModule().then((module) => ({ default: module.DashboardGadgetViewRenderer }))),
);

export const LazyDashboardGadgetEditIframe = withMetrics(
	lazy(() => getIframeModule().then((module) => ({ default: module.DashboardGadgetEditRenderer }))),
);

const getForgeExtensionModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-dashboard-gadget-extension" */ '@atlassian/jira-forge-ui/src/common/ui/extension/components/dashboard-gadget'
	);

export const LazyDashboardGadgetViewExtension = withMetrics(
	lazy(() =>
		getForgeExtensionModule().then((module) => ({ default: module.DashboardGadgetViewExtension })),
	),
);

export const LazyDashboardGadgetEditExtension = withMetrics(
	lazy(() =>
		getForgeExtensionModule().then((module) => ({ default: module.DashboardGadgetEditExtension })),
	),
);

const useDashboardGadget = <ED extends Omit<DashboardGadgetExtensionData, 'type'>>(
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void,
	extensionData: ED,
	entryPoint?: typeof ENTRY_POINT_EDIT,
) => {
	const onInitialRender = useCallback(
		(payload?: InitialRenderPayload) => {
			if (payload !== undefined && payload.error == null) {
				onReportGadgetMetrics(GADGET_FINISH_EVENT, 'View');
			} else {
				onReportGadgetMetrics(GADGET_FINISH_EVENT, 'ErrorMessage');
			}
		},
		[onReportGadgetMetrics],
	);

	useLayoutEffect(() => {
		onReportGadgetMetrics(GADGET_START_EVENT, 'View');
	}, [onReportGadgetMetrics]);

	return useMemo(() => {
		const extraPropsUiCommon = {
			module: DASHBOARD_GADGET_MODULE,
			entryPoint,
			extensionData: {
				...extensionData,
				entryPoint,
				type: DASHBOARD_GADGET_MODULE,
			},
		};
		return { onInitialRender, extraPropsUiCommon };
	}, [entryPoint, extensionData, onInitialRender]);
};

export const AsyncDashboardGadgetView = (props: ViewProps) => {
	const { extensionData, onReportGadgetMetrics, ...restProps } = props;
	const { onInitialRender, extraPropsUiCommon } = useDashboardGadget(
		onReportGadgetMetrics,
		extensionData,
	);
	const extraPropsUiKit = { ...extraPropsUiCommon, onInitialRender };
	const extraPropsCustomUi = { ...extraPropsUiCommon, onLoad: onInitialRender };

	if (
		(isNativeUiExtension(props.extension) || isCustomUIExtension(props.extension)) &&
		// https://switcheroo.atlassian.com/ui/gates/cd39682c-bc5b-4f4b-af87-f25672cfc326
		fg('jira_new_ui_kit_renderer')
	) {
		return <LazyDashboardGadgetViewExtension {...restProps} {...extraPropsCustomUi} />;
	}

	return isCustomUIExtension(props.extension) ? (
		<LazyDashboardGadgetViewIframe {...restProps} {...extraPropsCustomUi} />
	) : (
		<LazyDashboardGadgetView {...restProps} {...extraPropsUiKit} />
	);
};

export const AsyncDashboardGadgetEdit = (props: EditProps) => {
	const { extensionData, onReportGadgetMetrics, ...restProps } = props;
	const { onInitialRender, extraPropsUiCommon } = useDashboardGadget(
		onReportGadgetMetrics,
		extensionData,
		ENTRY_POINT_EDIT,
	);
	const extraPropsUiKitEdit = { ...extraPropsUiCommon, onInitialRender };
	const extraPropsCustomUiEdit = { ...extraPropsUiCommon, onLoad: onInitialRender };

	if (
		(isNativeUiExtension(props.extension, ENTRY_POINT_EDIT) ||
			isCustomUIExtension(props.extension, ENTRY_POINT_EDIT)) &&
		// https://switcheroo.atlassian.com/ui/gates/cd39682c-bc5b-4f4b-af87-f25672cfc326
		fg('jira_new_ui_kit_renderer')
	) {
		return <LazyDashboardGadgetEditExtension {...restProps} {...extraPropsCustomUiEdit} />;
	}

	return isCustomUIExtension(props.extension, ENTRY_POINT_EDIT) ? (
		<LazyDashboardGadgetEditIframe {...restProps} {...extraPropsCustomUiEdit} />
	) : (
		<LazyDashboardGadgetEdit {...restProps} {...extraPropsUiKitEdit} />
	);
};
